import _resolver from "./resolver";
import _util from "./util";
import _dagNode from "./dag-node/dagNode";
import _dagLink from "./dag-link/dagLink";
var exports = {};
const resolver = _resolver;
const util = _util;
const DAGNodeClass = _dagNode;
const DAGLinkClass = _dagLink;
/**
 * @typedef {import('./types').DAGLinkLike} DAGLinkLike
 * @typedef {import('./types').DAGNodeLike} DAGNodeLike
 * @typedef {import('./dag-node/dagNode')} DAGNode
 * @typedef {import('./dag-link/dagLink')} DAGLink
 */

/**
 * @type {import('./types').DAGNodeFormat}
 */

const format = {
  DAGNode: DAGNodeClass,
  DAGLink: DAGLinkClass,

  /**
   * Functions to fulfil IPLD Format interface
   * https://github.com/ipld/interface-ipld-format
   */
  resolver,
  util,
  codec: util.codec,
  defaultHashAlg: util.defaultHashAlg
};
exports = format;
export default exports;